import { APP_ID, ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { LOCALE_ID, importProvidersFrom } from '@angular/core';
import { environment } from '../environments/environment';
import { IvyCarouselModule } from 'angular-responsive-carousel2';
import { NgxBootstrapSliderModule } from '@nahuelmorata/ngx-bootstrap-slider';
import { GoogleMapsModule } from '@angular/google-maps';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AppRoutingModule } from './app.routes';
import { TransferHttpCacheModule } from '@nguniversal/common';
import {
  HammerModule,
  provideClientHydration
} from '@angular/platform-browser';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import {
  UserTrackingService,
  ScreenTrackingService
} from '@angular/fire/compat/analytics';
import { FavoriteService } from './marketplace/service/favorite.service';
import { DatetimeService } from './marketplace/service/datetime.service';
import { UserPreferenceService } from './marketplace/service/user-preferences.service';
import { VisitorsService } from './marketplace/service/visitors.service';
import {
  SharedService,
  SelectedSportService,
  SlotService,
  CenterAvailabilitiesService,
  StateService,
  InfoPaiementService
} from './marketplace/service/shared.service';
import { ActivityService } from './marketplace/service/activity.service';
import {
  provideHttpClient,
  HttpClient,
  withFetch,
  withInterceptorsFromDi,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import {
  provideCloudinaryLoader,
  CommonModule,
  NgOptimizedImage
} from '@angular/common';
import { AuthGuardService } from './auth-guard.service';
import { AuthFirebaseService } from './marketplace/service/auth-firebase.service';
import { AuthService } from './shared/services/auth.service';
import { AuthInterceptor } from './http-auth-interceptor';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFrBeExtra from '@angular/common/locales/extra/fr-BE';
import localeNlBeExtra from '@angular/common/locales/extra/nl-BE';
import localeFr from '@angular/common/locales/fr';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeNlBe from '@angular/common/locales/nl-BE';
import { HttpLoaderFactory } from './app.module';

registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeFrBe, 'fr-BE', localeFrBeExtra);
registerLocaleData(localeNlBe, 'nl-BE', localeNlBeExtra);
registerLocaleData(localeEs, 'es', localeEsExtra);

export const appConfig: ApplicationConfig = {
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    { provide: APP_ID, useValue: 'websitebooking' },
    importProvidersFrom(
      TransferHttpCacheModule,
      HammerModule,
      AppRoutingModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }),
      CommonModule,
      FormsModule,
      ReactiveFormsModule,
      GoogleMapsModule,
      NgxBootstrapSliderModule,
      IvyCarouselModule,
      NgOptimizedImage
    ),
    AuthService,
    AuthFirebaseService,
    AuthGuardService,
    provideCloudinaryLoader('https://res.cloudinary.com/anybuddy/'),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    ActivityService,
    SharedService,
    SelectedSportService,
    SlotService,
    VisitorsService,
    CenterAvailabilitiesService,
    UserPreferenceService,
    DatetimeService,
    StateService,
    InfoPaiementService,
    FavoriteService,
    UserTrackingService,
    ScreenTrackingService,
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: 'googleTagManagerId', useValue: environment.gtmId },
    { provide: '303254103213707', useValue: 'websitebooking' },
    provideClientHydration()
  ]
};
