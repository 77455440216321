import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { isDevMode, NgModule } from '@angular/core';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeFrBeExtra from '@angular/common/locales/extra/fr-BE';
import localeNlBeExtra from '@angular/common/locales/extra/nl-BE';
import localeFr from '@angular/common/locales/fr';
import localeFrBe from '@angular/common/locales/fr-BE';
import localeNlBe from '@angular/common/locales/nl-BE';

registerLocaleData(localeFr, 'fr', localeFrExtra);
registerLocaleData(localeFrBe, 'fr-BE', localeFrBeExtra);
registerLocaleData(localeNlBe, 'nl-BE', localeNlBeExtra);
registerLocaleData(localeEs, 'es', localeEsExtra);

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    '.json?cb=' + new Date().getTime()
  );
}

@NgModule({
  imports: [
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
})
export class AppModule { }
